import { ShopifyPrice, ShopifyProduct } from '@src/shop/store/shopify.slice'

class ShopifyService {
  private getPriceFromCurrencyCode(
    product: ShopifyProduct,
    currencyCode: string,
  ): ShopifyPrice['price'] {
    switch (currencyCode) {
      case 'EUR': {
        return product.variants[0]!.eur.price
      }
      case 'GBP': {
        return product.variants[0]!.gbp.price
      }
      case 'SEK': {
        return product.variants[0]!.sek.price
      }
      default: {
        return product.variants[0]!.usd.price
      }
    }
  }

  findPrice(product: ShopifyProduct, currencyCode: string, handle?: string): ShopifyPrice['price'] {
    const url = handle || (typeof window !== 'undefined' && window.location.href)
    if (!url) {
      return this.getPriceFromCurrencyCode(product, currencyCode)
    }

    const parts = url.split('/')
    const shopIndex = parts.indexOf('shop')
    const locationIndex = shopIndex + 1
    const urlLocation = parts[locationIndex]
    let price: ShopifyPrice['price']
    switch (urlLocation) {
      case 'us': {
        price = product.variants[0]!.usd.price
        break
      }
      case 'uk': {
        price = product.variants[0]!.gbp.price
        break
      }
      case 'se': {
        price = product.variants[0]!.sek.price
        break
      }
      case 'eu': {
        price = product.variants[0]!.eur.price
        break
      }
      case 'row': {
        price = product.variants[0]!.usd.price
        break
      }
      default: {
        // if we don't have a location, we base the price on the currency code
        // shouldn't happen though
        price = this.getPriceFromCurrencyCode(product, currencyCode)
      }
    }
    return price
  }
}

export const shopifyService = new ShopifyService()
