import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isE2eTest } from '@src/cnst/test.cnst'
import { SHOPIFY_US_DOMAIN } from '@src/helpers/env'
import { setCurrentUtmsInUrl } from '@src/helpers/queryParams'
import { regionKeys, ShippingCurrency, ShippingLocations } from '@src/shop/cnst/shopify.cnst'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { Thunk } from '@src/store'
import geolocation, { GeolocationCountries } from '@src/store/geolocation/geolocation.slice'
import Client from 'shopify-buy'

export enum AddToCartSources {
  Homepage = 'Homepage',
  ProductPage = 'ProductPage',
  BuyNowButton = 'BuyNowButton',
  FeaturedProduct = 'FeaturedProduct',
}

export interface ShopifyLineItem {
  variantId: string
  quantity: number
  title?: string
}

export interface ShopifyCart {
  [variantId: string]: ShopifyLineItem
}

export interface ShopifyPrice {
  price: {
    amount: string
    currencyCode: string
  }
}

export interface ShopifyProduct {
  id: string
  title: string
  handle: string
  tags: string[]
  description: string
  descriptionHtml: string
  images: {
    id: string
    originalSrc: string
  }[]
  seo: {
    title: string
    description: string
  }
  hasOutOfStockVariants: boolean
  variants: {
    id: string
    usd: ShopifyPrice
    sek: ShopifyPrice
    gbp: ShopifyPrice
    eur: ShopifyPrice
  }[]
  packageQuantity: string
  brandLogo: string
  shortDescription: string
}

export interface ShopifyPage {
  id: string
  handle: string
  title: string
  body: string
  bodySummary: string
}

export interface ShopifyPolicy {
  id: string
  slug: string
  title: string
  url: string
  body: string
}

// List of countries we don't ship webshop products to
export const shippingBlockList: GeolocationCountries[] = [
  GeolocationCountries.AF,
  GeolocationCountries.AO,
  GeolocationCountries.AI,
  GeolocationCountries.AQ,
  GeolocationCountries.AG,
  GeolocationCountries.AR,
  GeolocationCountries.AW,
  GeolocationCountries.AZ,
  GeolocationCountries.BS,
  GeolocationCountries.BH,
  GeolocationCountries.BD,
  GeolocationCountries.BB,
  GeolocationCountries.BZ,
  GeolocationCountries.BJ,
  GeolocationCountries.BM,
  GeolocationCountries.BT,
  GeolocationCountries.BO,
  GeolocationCountries.BQ,
  GeolocationCountries.BW,
  GeolocationCountries.BV,
  GeolocationCountries.IO,
  GeolocationCountries.BN,
  GeolocationCountries.BF,
  GeolocationCountries.BI,
  GeolocationCountries.CM,
  GeolocationCountries.KY,
  GeolocationCountries.CF,
  GeolocationCountries.TD,
  GeolocationCountries.CL,
  GeolocationCountries.CO,
  GeolocationCountries.KM,
  GeolocationCountries.CG,
  GeolocationCountries.CR,
  GeolocationCountries.CI,
  GeolocationCountries.CU,
  GeolocationCountries.CW,
  GeolocationCountries.CD,
  GeolocationCountries.DJ,
  GeolocationCountries.DM,
  GeolocationCountries.DO,
  GeolocationCountries.EC,
  GeolocationCountries.EG,
  GeolocationCountries.SV,
  GeolocationCountries.GQ,
  GeolocationCountries.ER,
  GeolocationCountries.ET,
  GeolocationCountries.FK,
  GeolocationCountries.GF,
  GeolocationCountries.GA,
  GeolocationCountries.GM,
  GeolocationCountries.GH,
  GeolocationCountries.GD,
  GeolocationCountries.GP,
  GeolocationCountries.GT,
  GeolocationCountries.GN,
  GeolocationCountries.GW,
  GeolocationCountries.GY,
  GeolocationCountries.HT,
  GeolocationCountries.HN,
  GeolocationCountries.ID,
  GeolocationCountries.IL,
  GeolocationCountries.IR,
  GeolocationCountries.IQ,
  GeolocationCountries.JM,
  GeolocationCountries.JO,
  GeolocationCountries.KZ,
  GeolocationCountries.KE,
  GeolocationCountries.KI,
  GeolocationCountries.KW,
  GeolocationCountries.KG,
  GeolocationCountries.LS,
  GeolocationCountries.LR,
  GeolocationCountries.LY,
  GeolocationCountries.MG,
  GeolocationCountries.MW,
  GeolocationCountries.ML,
  GeolocationCountries.MH,
  GeolocationCountries.MQ,
  GeolocationCountries.MR,
  GeolocationCountries.MU,
  GeolocationCountries.YT,
  GeolocationCountries.FM,
  GeolocationCountries.MS,
  GeolocationCountries.MA,
  GeolocationCountries.MZ,
  GeolocationCountries.NA,
  GeolocationCountries.NR,
  GeolocationCountries.NI,
  GeolocationCountries.NE,
  GeolocationCountries.NG,
  GeolocationCountries.NU,
  GeolocationCountries.OM,
  GeolocationCountries.PK,
  GeolocationCountries.PW,
  GeolocationCountries.PS,
  GeolocationCountries.PA,
  GeolocationCountries.PY,
  GeolocationCountries.PE,
  GeolocationCountries.PR,
  GeolocationCountries.QA,
  GeolocationCountries.RE,
  GeolocationCountries.RW,
  GeolocationCountries.BL,
  GeolocationCountries.KN,
  GeolocationCountries.LC,
  GeolocationCountries.MF,
  GeolocationCountries.PM,
  GeolocationCountries.VC,
  GeolocationCountries.ST,
  GeolocationCountries.SA,
  GeolocationCountries.SN,
  GeolocationCountries.SL,
  GeolocationCountries.SX,
  GeolocationCountries.SO,
  GeolocationCountries.ZA,
  GeolocationCountries.SS,
  GeolocationCountries.SD,
  GeolocationCountries.SR,
  GeolocationCountries.SZ,
  GeolocationCountries.SY,
  GeolocationCountries.TJ,
  GeolocationCountries.TZ,
  GeolocationCountries.TL,
  GeolocationCountries.TG,
  GeolocationCountries.TK,
  GeolocationCountries.TO,
  GeolocationCountries.TT,
  GeolocationCountries.TN,
  GeolocationCountries.TM,
  GeolocationCountries.TC,
  GeolocationCountries.TV,
  GeolocationCountries.UG,
  GeolocationCountries.AE,
  GeolocationCountries.UY,
  GeolocationCountries.UZ,
  GeolocationCountries.VU,
  GeolocationCountries.VE,
  GeolocationCountries.VN,
  GeolocationCountries.VG,
  GeolocationCountries.VI,
  GeolocationCountries.WF,
  GeolocationCountries.EH,
  GeolocationCountries.YE,
  GeolocationCountries.ZM,
  GeolocationCountries.ZW,
]

const sekCountries: GeolocationCountries[] = [GeolocationCountries.SE]

const gbpCountries: GeolocationCountries[] = [
  GeolocationCountries.FK,
  GeolocationCountries.GB,
  GeolocationCountries.GG,
  GeolocationCountries.GI,
  GeolocationCountries.IM,
  GeolocationCountries.JE,
]

export const eurCountries: GeolocationCountries[] = [
  GeolocationCountries.AD,
  GeolocationCountries.AT,
  GeolocationCountries.AX,
  GeolocationCountries.BE,
  GeolocationCountries.CH,
  GeolocationCountries.CZ,
  GeolocationCountries.CY,
  GeolocationCountries.DE,
  GeolocationCountries.DK,
  GeolocationCountries.EE,
  GeolocationCountries.ES,
  GeolocationCountries.EU,
  GeolocationCountries.FI,
  GeolocationCountries.FO,
  GeolocationCountries.FR,
  GeolocationCountries.GF,
  GeolocationCountries.GR,
  GeolocationCountries.IE,
  GeolocationCountries.IS,
  GeolocationCountries.IT,
  GeolocationCountries.LI,
  GeolocationCountries.LT,
  GeolocationCountries.LU,
  GeolocationCountries.LV,
  GeolocationCountries.MC,
  GeolocationCountries.MT,
  GeolocationCountries.NL,
  GeolocationCountries.NO,
  GeolocationCountries.PL,
  GeolocationCountries.PT,
  GeolocationCountries.RS,
  GeolocationCountries.SI,
  GeolocationCountries.SK,
  GeolocationCountries.SM,
  GeolocationCountries.UA,
  GeolocationCountries.VA,
]

export interface ShopifyState {
  cart: ShopifyCart
  currency: ShippingCurrency
  // we allow these to be undefined until decided, and let users of it handle the undefined case
  shippingLocation?: ShippingLocations
  expectedLocation?: ShippingLocations
  alteredRegion: boolean
}

const shopifyToken = process.env['GATSBY_SHOPIFY_US_TOKEN']!

const shopifyClient = Client.buildClient({
  domain: SHOPIFY_US_DOMAIN,
  storefrontAccessToken: shopifyToken,
  // https://shopify.dev/docs/api/usage/versioning
  apiVersion: '2023-01',
})

const initialState: ShopifyState = {
  cart: {},
  currency: ShippingCurrency.ROW,
  shippingLocation: ShippingLocations.ROW,
  alteredRegion: false,
}

// TODO: this can be simplified and made more readable and DRY
const slice = createSlice({
  name: 'shopify',
  initialState,

  reducers: {
    addToCart(shopify: ShopifyState, action: PayloadAction<ShopifyLineItem>) {
      const lineItem = action.payload
      if (shopify.cart[lineItem.variantId]) {
        shopify.cart[lineItem.variantId]!.quantity += lineItem.quantity
      } else {
        shopify.cart[lineItem.variantId] = lineItem
      }
    },

    changeLineItemQuantity(shopify: ShopifyState, action: PayloadAction<ShopifyLineItem>) {
      const lineItem = action.payload
      shopify.cart[lineItem.variantId]!.quantity = lineItem.quantity
    },

    removeFromCart(shopify: ShopifyState, action: PayloadAction<string>) {
      delete shopify.cart[action.payload]
    },

    changeShippingLocation(shopify: ShopifyState, action: PayloadAction<ShippingLocations>) {
      shopify.alteredRegion = true
      const previousLocation = shopify.shippingLocation
      if (previousLocation === action.payload) return
      // Availability of items differ between regions, so we need to clear the cart if the region changes
      sessionStorage.removeItem('cart')
      shopify.shippingLocation = action.payload
    },

    setShippingLocationByCountry(
      shopify: ShopifyState,
      action: PayloadAction<GeolocationCountries>,
    ) {
      const country = action.payload
      let shippingLocation: ShippingLocations | undefined

      regionKeys.forEach(region => {
        if (region === country) {
          shippingLocation = ShippingLocations[region]
          return
        }
        if (region === 'EU' && eurCountries.includes(country)) {
          shippingLocation = ShippingLocations.EU
          return
        }
      })
      if (shippingBlockList.includes(country)) {
        shippingLocation = ShippingLocations.Blocked
      }
      shopify.shippingLocation = shippingLocation
      shopify.expectedLocation = shippingLocation
      shopify.alteredRegion = true
    },

    setExpectedLocation(
      shopify: ShopifyState,
      action: PayloadAction<ShippingLocations | undefined>,
    ) {
      shopify.expectedLocation = action.payload
    },

    alteredRegion(shopify: ShopifyState, action: PayloadAction<boolean>) {
      shopify.alteredRegion = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(geolocation.actions.success, (state, action) => {
      if (isE2eTest()) {
        state.shippingLocation = ShippingLocations.US
        state.currency = ShippingCurrency.US
        return
      }

      const checkWindow = typeof window !== 'undefined'
      const savedRegion = checkWindow && window.sessionStorage.getItem('Region:')

      // check if there is a saved region first
      if (savedRegion) {
        state.alteredRegion = true

        regionKeys.forEach(region => {
          if (ShippingLocations[region] === savedRegion) {
            state.shippingLocation = ShippingLocations[region]
            state.currency = ShippingCurrency[region]
          }
        })
      } else {
        const country = action.payload
        let _shippingLocation: ShippingLocations | undefined

        regionKeys.forEach(region => {
          if (region === country) {
            _shippingLocation = ShippingLocations[region]
            return
          }
          if (region === 'EU' && eurCountries.includes(country)) {
            _shippingLocation = ShippingLocations.EU
            return
          }
        })
        if (shippingBlockList.includes(country)) {
          _shippingLocation = ShippingLocations.Blocked
        }
        // eslint-disable-next-line no-useless-assignment
        _shippingLocation ||= ShippingLocations.ROW

        // Set currency code
        if (sekCountries.includes(action.payload)) {
          state.currency = ShippingCurrency.SE
        } else if (gbpCountries.includes(action.payload)) {
          state.currency = ShippingCurrency.GB
        } else if (eurCountries.includes(action.payload)) {
          state.currency = ShippingCurrency.EU
        } else {
          state.currency = ShippingCurrency.ROW
        }
      }
    })
  },
})

export const addToCart =
  (product: ShopifyProduct, quantity: number, source: AddToCartSources): Thunk =>
  async (dispatch, getState) => {
    const title = product.title
    const variantId = product.variants[0]!.id
    const lineItem: ShopifyLineItem = { variantId, quantity, title }

    dispatch(slice.actions.addToCart(lineItem))
    persistCart(getState().shopify.cart)

    // don't track buy now button cart adds as these have their own mixpanel event
    if (source !== AddToCartSources.BuyNowButton) {
      mixpanelService.track('ShopifyAddToCart', {
        product: title,
        quantity,
        source,
      })
    }
  }

export const changeLineItemQuantity =
  (variantId: string, quantity: number, title: string): Thunk =>
  async (dispatch, getState) => {
    if (quantity === 0) {
      dispatch(removeFromCart(variantId, title))
      return
    }

    const lineItem: ShopifyLineItem = { variantId, quantity }
    dispatch(slice.actions.changeLineItemQuantity(lineItem))
    persistCart(getState().shopify.cart)

    mixpanelService.track('ShopifyChangeLineItemQuantity', {
      product: title,
      quantity,
    })
  }

export const removeFromCart =
  (variantId: string, title: string): Thunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.removeFromCart(variantId))
    persistCart(getState().shopify.cart)

    mixpanelService.track('ShopifyRemoveFromCart', {
      product: title,
    })
  }

export const buyNow =
  (product: ShopifyProduct, quantity: number): Thunk =>
  async dispatch => {
    mixpanelService.trackClick('ShopifyBuySingleProduct', {
      product: product.title,
      quantity,
    })

    dispatch(addToCart(product, quantity, AddToCartSources.BuyNowButton))
    dispatch(checkout())
  }

export const clearCart = () => {
  sessionStorage.removeItem('cart')
}

export const checkout = (): Thunk => async (_dispatch, getState) => {
  const state = getState()
  const products = state.shopify.cart
  const alteredRegion = state.shopify.alteredRegion
  const geolocation = state.geolocation.country

  const lineItems = []
  for (const value of Object.values(products)) {
    lineItems.push({ variantId: value.variantId, quantity: value.quantity })
  }

  const setUsersRegion = () => {
    const region = state.shopify.shippingLocation
    let country: GeolocationCountries

    if (region === ShippingLocations.EU) {
      country = GeolocationCountries.DE
    } else if (region === ShippingLocations.GB) {
      country = GeolocationCountries.GB
    } else if (region === ShippingLocations.SE) {
      country = GeolocationCountries.SE
    } else {
      country = GeolocationCountries.US
    }
    return country
  }

  const checkout = await shopifyClient.checkout.create({
    lineItems,
    /**
     * It exists
     * https://shopify.dev/docs/api/storefront/2023-01/input-objects/CheckoutCreateInput
     */
    // @ts-expect-error
    buyerIdentity: {
      countryCode: !alteredRegion ? geolocation : setUsersRegion(),
    },
  })

  window.location.href = setCurrentUtmsInUrl(checkout.webUrl).toString()
}

export const setShippingLocationByCountry =
  (country: GeolocationCountries): Thunk =>
  dispatch => {
    dispatch(slice.actions.setShippingLocationByCountry(country))
  }

export const setShippingLocation =
  (location: any): Thunk =>
  dispatch => {
    dispatch(slice.actions.changeShippingLocation(location))
  }

export const setExpectedLocation =
  (location?: ShippingLocations): Thunk =>
  dispatch => {
    dispatch(slice.actions.setExpectedLocation(location))
  }

export const setAlteredRegion =
  (alteredRegion: boolean): Thunk =>
  dispatch => {
    dispatch(slice.actions.alteredRegion(alteredRegion))
  }

export const selectShopify = ({ shopify }: { shopify: ShopifyState }): ShopifyState => shopify

export const selectShopifyShippingLocation = createSelector(
  [selectShopify],
  (shippingLocation): ShippingLocations | undefined => {
    return shippingLocation.shippingLocation
  },
)

export const selectExpectedLocation = createSelector(
  [selectShopify],
  (expectedLocation): ShippingLocations | undefined => {
    return expectedLocation.expectedLocation
  },
)

export const selectShopifyCurrency = createSelector(
  [selectShopify],
  (shippingCurrency): ShippingCurrency => {
    return shippingCurrency.currency
  },
)

function persistCart(cart: ShopifyCart): void {
  sessionStorage.setItem('cart', JSON.stringify(cart))
}

export default slice
