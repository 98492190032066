import { AdminService } from '@naturalcycles/frontend-lib'
import locales from '@src/cnst/locales.cnst'
import { SupportedLocale, translations } from '@src/cnst/translations.cnst'
import { Footer } from '@src/components/footer/Footer.component'
import { GlobalSeo } from '@src/components/global-seo/GlobalSeo.component'
import { Header } from '@src/components/header/Header.component'
import { QaMenu } from '@src/components/qaMenu/QaMenu.component'
import LanguageContext from '@src/context/language.context'
import QaContext from '@src/context/qa.context'
import TranslationContext from '@src/context/translation.context'
import { gtmService } from '@src/srv/gtm.service'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { optimoveService } from '@src/srv/optimove.service'
import { retargetingService } from '@src/srv/retargeting.service'
import { initSentry } from '@src/srv/sentry.service'
import { store } from '@src/store'
import {
  setDiscountCode,
  setDiscountName,
  setDiscountPercentage,
  setDiscountReferral,
} from '@src/store/discountCode/discountCode.slice'
import { closeMobileMenu } from '@src/store/mobileMenu/mobileMenu.slice'
import { setPathname } from '@src/store/pathname/pathname.slice'
import { disablePopups } from '@src/store/popup/popup.slice'
import {
  GatsbyBrowser,
  RouteUpdateArgs,
  ShouldUpdateScrollArgs,
  WrapPageElementBrowserArgs,
} from 'gatsby'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { onCLS, onFCP, onFID, onLCP, onTTFB } from 'web-vitals'
import { addShoppersService } from './addShoppers.service'
import { webInitService } from './init.service'

class BrowserService implements GatsbyBrowser {
  onClientEntry(): void {
    // Please note that destructuring doesn't work in Gatsby, because magic (https://stackoverflow.com/a/58507413/4919972)
    // const { GATSBY_ENV, API_URL } = process.env
    const GATSBY_ENV = process.env['GATSBY_ENV']
    const GATSBY_VERSION_NUMBER = process.env['GATSBY_VERSION_NUMBER']

    console.log({
      GATSBY_ENV,
      GATSBY_VERSION_NUMBER,
    })

    initSentry()

    mixpanelService.init()
    void webInitService.init()

    const typeformQuery = 'a[href^="https://naturalcycles.typeform.com/"]'
    const typeformLink = document.querySelector(typeformQuery)
    if (typeformLink) {
      typeformLink.addEventListener('click', () => {
        mixpanelService.trackClick('typeform-link')
      })
    }
    mixpanelService.trackImpression()
    void optimoveService.trackImpression()

    window.addEventListener('load', () => {
      gtmService.init()
      gtmService.trackImpression()
      mixpanelService.track('LandingPageLoaded', {
        utm_source_web: 'ncweb_init',
      })
    })

    // set persistent discount code based on url parameters
    const searchParams = new URLSearchParams(window.location.search)

    // to be used only for leadCapture discount for users that have as seen a 20% off

    const leadCaptureCode = searchParams.get('lead')
    if (leadCaptureCode) {
      window.sessionStorage.setItem('leadCaptureDiscount', leadCaptureCode)
      const url = new URL(window.location.href)
      url.searchParams.delete('lead')
      history.replaceState(null, '', url.href)
    }

    if (searchParams.has('code') || searchParams.has('name')) {
      const name = searchParams.get('name')?.trim()
      const percentage = searchParams.get('percentage')?.trim()
      const code = searchParams.get('code')?.trim()
      const referral = searchParams.get('referral')?.trim()

      if (code) {
        store.dispatch(setDiscountCode(code, 'UrlParameters'))
      }

      if (percentage) {
        store.dispatch(setDiscountPercentage(`${percentage}% OFF`))
      } else {
        store.dispatch(setDiscountPercentage(''))
      }

      if (name) {
        store.dispatch(setDiscountName(name, !!referral))
      } else {
        store.dispatch(setDiscountName(''))
      }

      if (referral && code) {
        if (code.toLocaleLowerCase().includes('lh')) {
          store.dispatch(setDiscountPercentage(`${code.slice(-4, -2)}% OFF`))
        } else {
          store.dispatch(setDiscountPercentage(`${code.slice(-2)}% OFF`))
        }
        store.dispatch(setDiscountReferral(referral))
      } else {
        store.dispatch(setDiscountReferral(''))
      }

      // If the user arrives with any of these url parameters we remove them from the url
      // Unless they are a friend or influencer referral
      // We then keep them to prevent parameters from being deleted if the user
      // switches between in-app browser and their regular browser

      if (!name) {
        const url = new URL(window.location.href)
        url.searchParams.delete('name')
        url.searchParams.delete('referral')
        url.searchParams.delete('code')
        url.searchParams.delete('percentage')

        window.location.assign(url.href)
        // The replaceState function doesn't work anymore (likely since Gatsby 4)
        // history.replaceState({ code: 'code' }, '', url.href)
      }
    }

    void addShoppersService.init()
    retargetingService.init()

    // Set Impact tracking id cookie
    if (searchParams.has('irclickid')) {
      Cookies.set('irclickid', searchParams.get('irclickid')!, {
        // Cookie expiration time in days
        expires: 30,
        domain: '.naturalcycles.com',
      })
    }

    // If the user is on chromium or firefox report web vitals to mixpanel
    const userAgent = window.navigator.userAgent

    if (userAgent.match(/Chrome/) || userAgent.match(/Firefox/)) {
      interface WebVitalsProps {
        name: string
        value: number
      }
      const path = window.location.pathname

      const sendToMixpanel = ({ name, value }: WebVitalsProps) => {
        mixpanelService.track('Web Vitals', { name, value, page: path })
      }

      if (userAgent.match(/Chrome/)) {
        onCLS(sendToMixpanel)
        onLCP(sendToMixpanel)
      }
      onFID(sendToMixpanel)
      onTTFB(sendToMixpanel)
      onFCP(sendToMixpanel)
    }
  }

  onRouteUpdate({ location, prevLocation }: RouteUpdateArgs): void {
    if (!prevLocation) {
      // onRouteUpdate fires on the initial page load as well as for navigation
      // events. We're only interested in navigation events here so we discard
      // the page load case by checking for a prevLocation
      return
    }

    const oldPath = prevLocation.pathname
    const newPath = location.pathname

    const leavingHowItWorks = /how-does-natural-cycles-work\/?$/.test(oldPath)
    const leavingIsItForMe = /is-natural-cycles-right-for-me\/?$/.test(oldPath)
    const enteringCyclematters = newPath.startsWith('/cyclematters')

    if ((leavingHowItWorks || leavingIsItForMe) && enteringCyclematters) {
      store.dispatch(disablePopups())
    }

    store.dispatch(closeMobileMenu())
    store.dispatch(setPathname(window.location.pathname))
    gtmService.trackImpression()
    mixpanelService.trackImpression()
    void optimoveService.trackImpression()
  }

  wrapPageElement({ element, props }: WrapPageElementBrowserArgs): React.ReactElement {
    // Not the best way to check if the page is based on dynamic template, but it works in absence of a better solution
    const isDynamicTemplate = props.data && !!props.data['datoCmsDynamicTemplate']

    const path = props.location.pathname
    const part = path.split('/').slice(1)[0]!
    const [displayMenu, setDisplayMenu] = useState(false)
    const [adminService] = useState(
      new AdminService({
        onRedDotClick: () => {
          setDisplayMenu(prev => !prev)
        },
      }),
    )

    useEffect(() => {
      adminService.startListening()
      return () => adminService.stopListening()
    }, [])

    async function toggleRedDot() {
      await adminService.toggleRedDot()
    }

    const { language, locale } = locales.find(l => l.url === part) || locales[0]!
    const stringsMap = translations[locale]

    return (
      <LanguageContext.Provider
        // we default to en-US if the locale is en-CA for DRYness
        value={{
          language,
          locale,
          fallbackLocale: locale === SupportedLocale.enCA ? SupportedLocale.enUS : undefined,
        }}
      >
        <TranslationContext.Provider value={stringsMap}>
          <QaContext.Provider value={{ displayMenu, toggleRedDot }}>
            <GlobalSeo>
              <Header canHaveDiscountBanner={!isDynamicTemplate} />
              <QaMenu />
              {element}
              {/* Dynamic Template manages the footer from Dato */}
              {!isDynamicTemplate && <Footer />}
            </GlobalSeo>
          </QaContext.Provider>
        </TranslationContext.Provider>
      </LanguageContext.Provider>
    )
  }

  shouldUpdateScroll({
    routerProps: { location },
    getSavedScrollPosition,
  }: ShouldUpdateScrollArgs): boolean {
    window.history.scrollRestoration = 'manual'
    const currentPosition = getSavedScrollPosition(location)
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        window.scrollTo(...(currentPosition || [0, 0]))
      })
    }, 0)

    return false
  }
}

export const browserService = new BrowserService()
