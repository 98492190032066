import { _sum, _sumBy } from '@naturalcycles/js-lib'
import { useLocation } from '@reach/router'
import Button from '@src/components/button/Button.component'
import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import { ShippingLocations } from '@src/shop/cnst/shopify.cnst'
import { shopifyService } from '@src/shop/srv/shopify.service'
import {
  changeLineItemQuantity,
  removeFromCart,
  selectShopifyShippingLocation,
} from '@src/shop/store/shopify.slice'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import FeaturedProductsMiniCart from '../featured-products/FeaturedProductsMiniCart.component'
import ProductQuantityControl from '../product-quantity-control/ProductQuantityControl.component'
import styles from './Minicart.module.scss'

interface ProductVariant {
  id: string
  title: string
  price: string
  usd: { price: { amount: string; currencyCode: string } }
  sek: { price: { amount: string; currencyCode: string } }
  gbp: { price: { amount: string; currencyCode: string } }
  eur: { price: { amount: string; currencyCode: string } }
}

interface ProductNode {
  id: string
  title: string
  handle: string
  description: string
  tags: string[]
  descriptionHtml: string
  seo: {
    title: string
    description: string
  }
  hasOutOfStockVariants: boolean
  images: { id: string; originalSrc: string; altText: string }[]
  variants: ProductVariant[]
  packageQuantity?: string // packageQuantity is now a string
  brandLogo?: string
  shortDescription?: string
}

interface ProductWrapper {
  node: ProductNode
}

interface MiniCartProps {
  openCart: boolean
  setOpenCart: React.Dispatch<React.SetStateAction<boolean>>
}

const MiniCart = ({ openCart, setOpenCart }: MiniCartProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const cart = useAppSelector(state => state.shopify.cart)
  const cartSize = _sumBy(Object.values(cart), c => c.quantity)
  const location = useLocation()
  const _isMobile = useBreakpoint().breakpoint < Breakpoints.bpMedium

  const currentCurrency = useAppSelector(state => state.shopify.currency)
  const [currencyCode, setCurrencyCode] = React.useState('USD')
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const cartPath = `/shop/cart${window.location.search}`
  const isSwedenShipping = shippingLocation === ShippingLocations.SE
  const ovulationTestHref =
    '/shop/' +
    (isSwedenShipping ? 'agglossningstest-fran-natural-cycles' : '15-ovulation-tests') +
    window.location.search

  const isFirstRender = useRef(true)

  const freeShippingLimit = currencyCode === 'SEK' ? 300 : currencyCode === 'GBP' ? 25 : 30

  const data = useStaticQuery(graphql`
    query {
      allShopifyProduct {
        edges {
          node {
            id
            title
            handle
            description
            images {
              originalSrc
              altText
            }
            variants {
              id
              title
              price
              usd {
                price {
                  amount
                  currencyCode
                }
              }
              sek {
                price {
                  amount
                  currencyCode
                }
              }
              gbp {
                price {
                  amount
                  currencyCode
                }
              }
              eur {
                price {
                  amount
                  currencyCode
                }
              }
            }
            packageQuantity
            brandLogo
            shortDescription
          }
        }
      }
    }
  `)

  const products: ProductWrapper[] = data.allShopifyProduct.edges

  // Step 1: Extract the variant IDs from the cart object keys
  const cartVariantIds = Object.keys(cart)

  // Step 2: Log each product's node.id and compare with cartVariantIds
  const productsNotInCart = products.filter(productWrapper => {
    const productNodeId = productWrapper.node.id

    // Check if the product's node.id is in the cart's variant IDs
    const isInCart = cartVariantIds.includes(productNodeId)

    return !isInCart
  })

  // Step 3: Extract the titles of up to 3 products not in the cart
  const productsInCartArray = productsNotInCart
    .slice(0, 6)
    .map(productWrapper => productWrapper.node.title)

  const toggleMiniCart = async (
    e: React.MouseEvent<Element>,
    bypass = false,
    link = '',
  ): Promise<void> => {
    setOpenCart(false)

    if (!bypass) {
      e.preventDefault()
    } else {
      if (link) {
        console.log('NAVIGATE TO LINK', link)
        await navigate(link)
      }
    }
  }

  const calculatePrice = (): number => {
    return _sum(
      Object.values(cart).map(lineItem => {
        const variantId = lineItem.variantId
        const productWrapper = products.find(p => p.node?.variants.some(v => v.id === variantId))
        const product = productWrapper?.node

        if (product) {
          const priceObj = shopifyService.findPrice(product as any, currencyCode) // Cast to 'any' to avoid type errors
          return Number.parseFloat(priceObj.amount) * lineItem.quantity
        }

        return 0
      }),
    )
  }

  const onRemove = (id: string, title: string) => {
    dispatch(removeFromCart(id, title))
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    if (cartSize === 1 && location.pathname !== '/shop/cart') {
      setOpenCart(true)
    }
  }, [cartSize, setOpenCart, location.pathname])

  useEffect(() => {
    setCurrencyCode(currentCurrency)
  }, [currentCurrency])

  return (
    <div
      className={openCart ? `${styles['MiniCart']} ${styles['MiniCartIsOpen']}` : styles.MiniCart}
    >
      <div className={styles.MiniCart__sneeze} onClick={e => toggleMiniCart(e)} />
      <div className={styles.MiniCart__container}>
        <div className={styles.MiniCart__cartHeading}>
          Your Cart {Object.keys(cart).length ? `(${cartSize})` : ''}
        </div>

        <button onClick={toggleMiniCart} className={styles.MiniCart__button}>
          <span>close mini cart</span>+
        </button>

        <div className={styles.MiniCart__empty}>
          {!Object.keys(cart).length && (
            <div className={styles.MiniCart__cartEmpty}>
              Cart is empty, did you want to buy some LH-tests?{' '}
              <a href={ovulationTestHref}> Find them here</a>.
            </div>
          )}
        </div>
        {Object.keys(cart).length > 0 && (
          <>
            <div className={styles.MiniCart__productsContainer}>
              {Object.values(cart).map((lineItem, _index) => {
                const variantId = lineItem.variantId
                const productWrapper = products.find(p =>
                  p.node?.variants.some(v => v.id === variantId),
                )
                const product = productWrapper?.node

                if (!product) {
                  return <div key={'pnf'}>Product not found</div>
                }

                const onChangeQuantity = (quantity: number) => {
                  dispatch(changeLineItemQuantity(lineItem.variantId, quantity, product.title))
                }

                return (
                  <div key={product.title} className={styles.MiniCart__productCard}>
                    <img
                      className={styles.MiniCart__productImage}
                      src={product.images[0]?.originalSrc || ''}
                      alt={product.title}
                    />
                    <div className={styles.MiniCart__productTitle}>{product.title}</div>
                    {product.packageQuantity && (
                      <div className={styles.MiniCart__productQtyAmt}>
                        {product.packageQuantity}
                      </div>
                    )}
                    <div className={styles.MiniCart__productPrice}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: shopifyService.findPrice(product as any, currencyCode)
                          .currencyCode, // Cast to 'any' to avoid type errors
                      }).format(
                        Number.parseFloat(
                          shopifyService.findPrice(product as any, currencyCode).amount,
                        ), // Cast to 'any' to avoid type errors
                      )}
                    </div>
                    <div className={styles.MiniCart__productQuantityControl}>
                      <ProductQuantityControl
                        quantity={lineItem.quantity}
                        minimum={0}
                        maximum={9}
                        onChange={onChangeQuantity}
                        productName={product.title}
                      />
                    </div>
                    <div className={styles.MiniCart__productRemoveItems}>
                      <button
                        onClick={() => onRemove(product.variants[0]!.id, product.title)}
                        aria-label={`Remove all items of product ${product.title}`}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                )
              })}
              {_isMobile && (
                <div className={styles.MiniCart__featuredProducts}>
                  <FeaturedProductsMiniCart
                    customHeadline="Featured Products"
                    currentProducts={productsInCartArray}
                  />
                </div>
              )}
            </div>
            {!_isMobile && (
              <div className={styles.MiniCart__featuredProducts}>
                <FeaturedProductsMiniCart
                  customHeadline="Featured Products"
                  currentProducts={productsInCartArray}
                />
              </div>
            )}
          </>
        )}
        {Object.keys(cart).length > 0 && (
          <div className={styles.MiniCart__checkoutContainer}>
            <div className={styles.MiniCart__totalHeading}>Estimated Total:</div>
            <div className={styles.MiniCart__totalDisclaimer}>
              {' '}
              {calculatePrice() < freeShippingLimit
                ? '(Excludes delivery charges)'
                : 'Includes free shipping'}
            </div>
            <div className={styles.MiniCart__totalPrice}>
              {new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(
                calculatePrice(),
              )}
            </div>
            <div className={styles.MiniCart__checkoutButton}>
              <Button onClick={e => toggleMiniCart(e, true, cartPath)} theme={'brandPurple'} shop>
                Continue to Checkout
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MiniCart
