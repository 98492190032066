export type SupportedCurrencies = 'USD' | 'EUR' | 'SEK' | 'GBP'

export enum ShippingLocations {
  SE = 'shipping-SE',
  US = 'shipping-US',
  GB = 'shipping-UK',
  ROW = 'shipping-ROW',
  EU = 'shipping-EU',
  Blocked = 'Blocked',
}

export enum ShippingCurrency {
  SE = 'SEK',
  US = 'USD',
  GB = 'GBP',
  // todo:
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ROW = 'USD',
  EU = 'EUR',
}

export enum RegionSlug {
  SE = 'se',
  US = 'us',
  GB = 'uk',
  ROW = 'row',
  EU = 'eu',
}

export enum StorageRegion {
  SE = 'se-sek',
  US = 'us-usd',
  GB = 'uk-gbp',
  ROW = 'international-usd',
  EU = 'eu-eur',
}

export enum RegionDescriptor {
  SE = 'Sweden <span class="RegionPicker__pipe">|</span> SEK',
  US = 'United States <span class="RegionPicker__pipe">|</span> USD',
  GB = 'United Kingdom <span class="RegionPicker__pipe">|</span> GBP',
  ROW = 'International <span class="RegionPicker__pipe">|</span> USD',
  EU = 'Europe <span class="RegionPicker__pipe">|</span> EUR',
}

export type RegionKey = keyof typeof StorageRegion

export const regionKeys = Object.keys(StorageRegion) as RegionKey[]

interface RegionPage {
  shopifyTag: ShippingLocations
  slug: RegionSlug
}

export const shippingRegionPages: RegionPage[] = regionKeys.map(region => ({
  shopifyTag: ShippingLocations[region],
  slug: RegionSlug[region],
}))
